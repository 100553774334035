/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CalendarOutlined,
  SearchOutlined,
  UserOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import { AiOutlineFilter } from "react-icons/ai";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { BsDash } from "react-icons/bs";
import { Link, useLocation } from "react-router-dom";
// @ts-ignore
import { useMediaQuery } from "react-responsive";
import moment from "moment";

import Table from "./Table";
import Loader from "./Loader";
import Input from "./Input";
import DatePicker from "./DatePicker";
import {
  getCustomersList,
  getEnumTypeList,
  getGeoCountries,
  getGeoStates,
  getOrderListing,
  getStatusList,
  searchProductListing,
  userSelector,
} from "../redux/authSlice";
import { RiDownloadLine } from "react-icons/ri";
import { CSVLink } from "react-csv";
import { Menu, Dropdown } from "antd";
import Button from "./Button";
import { Form, Modal, Select } from "antd";
import CustomDropdown from "./Dropdown";
import { debounceFn } from "../helper/function";

const FilterSearchBar = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
}: {
  setSelectedKeys: any;
  selectedKeys: any;
  confirm: any;
  clearFilters: any;
}) => {
  return (
    <div style={{ padding: 8 }}>
      <div>
        <Input
          title=""
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e: any) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onKeyDown={(e: any) => {
            if (e.key === "Enter") {
              confirm();
            }
          }}
          onBlur={confirm}
        />
        <div className="mt-3 flex flex-row items-center justify-between">
          <button
            onClick={clearFilters}
            className="px-5 text-xs sm:px-3 sm:text-sm bg-transparent focus:outline-none border border-black text-black font-semibold py-1 rounded-lg"
          >
            Reset
          </button>
          <button
            onClick={() => {
              document
                .getElementsByClassName("ant-dropdown")[0]
                .classList.add("ant-dropdown-hidden");
              confirm();
            }}
            className="px-5 text-xs sm:px-3 sm:text-sm bg-primary focus:outline-none border text-white font-semibold py-1 rounded-lg"
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
};

const OrderListing = ({
  fromDate,
  thruDate,
  facilityId,
  statusId,
  queryString,
  onlyShipped,
  isReturnedOrders,
}: {
  fromDate?: any;
  thruDate?: any;
  facilityId?: any;
  statusId?: any;
  queryString?: any;
  onlyShipped: boolean;
  isReturnedOrders?: any;
}) => {
  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 638px)",
  });
  const dispatch = useDispatch();
  const location = useLocation();
  const { activeOrgStores, customersList, countriesList, statesList } =
    useSelector(userSelector);
  const [AdvanceFindForm] = Form.useForm();
  const [currIndex, setCurrIndex] = useState<any>("1");
  const [isLoading, setIsLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [customerSearchLoading, setIsCustomerSearchLoading] = useState(false);
  const [advanceFindLoading, setIsAdvanceFindLoading] = useState(false);
  const [getOrderListingDetail, setgetOrderListingDetail] = useState<any>("");
  const [getBulkOrderListingDetail, setGetBulkOrderListingDetail] =
    useState<any>("");
  const [recordCount, setRecordCount] = useState("");
  const [orderStatus, setOrderStatus] = useState<any>([]);
  const [returnStatus, setReturnStatus] = useState<any>([]);
  const [shipmentStatus, setShipmentStatus] = useState<any>([]);
  const [maxPageIndex, setMaxPageIndex] = useState<any>("");
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [productList, setProductList] = useState<any>([]);
  const [custMaxPageIndex, setCustMaxPageIndex] = useState<any>("");
  const [currCustListIndex, setCurrCustListIndex] = useState<any>("1");
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearch, setIsSearch] = useState<any>("");
  const [searchableString, setSearchableString] = useState<any>([]);
  const [enumType, setEnumType] = useState<any>([]);
  const [orderType, setOrderType] = useState<any>([]);
  const [sortData, setSortData] = useState<any>("");
  const [activeAppliedFilters, setActiveAppliedFilters] = useState("");
  const [shipMethodList, setShipMethodList] = useState([]);
  const [pageSize, setPageSize] = useState<any>();
  const [, setPageCount] = useState<any>("");
  const [advanceFindAppliedFilter, setAdvanceFindAppliedFilter] = useState<any>(
    {}
  );
  const [selectedFromDate, setSelectedFromDate] = useState(
    fromDate
      ? fromDate
      : localStorage.getItem("orderListingFromDate")
      ? localStorage.getItem("orderListingFromDate")
      : ""
  );
  const [selectedThruDate, setSelectedThruDate] = useState(
    thruDate
      ? thruDate
      : localStorage.getItem("orderListingThruDate")
      ? localStorage.getItem("orderListingThruDate")
      : ""
  );
  const [filteredInfo, setFilteredInfo] = useState<any>({});
  const [sortedInfo, setSortedInfo] = useState<any>({});
  const [datePickerFrom, setDatePickerFrom] = useState<any>(
    fromDate
      ? moment(fromDate, "YYYY-MM-DD")
      : localStorage.getItem("orderListingFromDate")
      ? moment(localStorage.getItem("orderListingFromDate"), "YYYY-MM-DD")
      : ""
  );
  const [datePickerThru, setDatePickerThru] = useState<any>(
    thruDate
      ? moment(thruDate, "YYYY-MM-DD")
      : localStorage.getItem("orderListingThruDate")
      ? moment(localStorage.getItem("orderListingThruDate"), "YYYY-MM-DD")
      : ""
  );
  const [searchedQuery, setSearchedQuery] = useState("");
  const [resetInputField, setResetInputField] = useState<any>(false);
  const [selectedColumns, setSelectedColumns] = useState<any>([]);
  const [advanceFindOpen, setAdvanceFindOpen] = useState(false);
  const salesChannelFilters: any = [];
  const statusFilters: any = [];
  const shipmentstatusFilters: any = [];
  const shipMethodFilters: any = [];
  useEffect(() => {
    const fetchData = async () => {
      if (fromDate) {
        localStorage.setItem("orderListingFromDate", fromDate);
      }
      if (thruDate) {
        localStorage.setItem("orderListingThruDate", thruDate);
      }
      let prevAppliedFilter: any = localStorage.getItem("prevAppliedFilter");
      if (!prevAppliedFilter) {
        prevAppliedFilter = {};
      } else {
        prevAppliedFilter = JSON.parse(prevAppliedFilter);
        console.log("125", prevAppliedFilter);
        setSortedInfo(prevAppliedFilter?.sortedInfo);
        setFilteredInfo(prevAppliedFilter?.filteredInfo);
        setActiveAppliedFilters(prevAppliedFilter?.appliedFilters);
      }
      setIsLoading(true);
      if (isSearch === "Searched") {
        await dispatch(
          getOrderListing({
            setgetOrderListingDetail,
            setGetBulkOrderListingDetail,
            setMaxPageIndex,
            orderByFields: " ",
            setRecordCount,
            pageIndex: 0,
            pageSize,
            queryString: searchableString,
            onlyShipped,
            appliedFilters: "",
            isReturnedOrders: isReturnedOrders ? true : false,
          })
        );
      } else {
        await dispatch(
          getOrderListing({
            setgetOrderListingDetail,
            setGetBulkOrderListingDetail,
            setMaxPageIndex,
            orderByFields: prevAppliedFilter?.orderByFields
              ? prevAppliedFilter?.orderByFields
              : sortData,
            setRecordCount,
            pageIndex: localStorage.getItem("currPageIndex")
              ? localStorage.getItem("currPageIndex")
              : 0,
            // searchString: orderStatus,
            placedDate_from: selectedFromDate
              ? selectedFromDate
              : fromDate
              ? fromDate
              : "",
            placedDate_thru: selectedThruDate
              ? selectedThruDate
              : thruDate
              ? thruDate
              : "",
            statusId,
            facilityId: facilityId ? facilityId : "",
            queryString,
            onlyShipped,
            isReturnedOrders: isReturnedOrders ? true : false,
            appliedFilters: prevAppliedFilter?.appliedFilters
              ? prevAppliedFilter.appliedFilters
              : "",
          })
        );
      }
      setIsLoading(false);
      await dispatch(
        getCustomersList({
          setMaxPageIndex: setCustMaxPageIndex,
          pageIndex: 0,
        })
      );
      await dispatch(getGeoCountries());
      await dispatch(
        getStatusList({
          setStatus: setOrderStatus,
          statusTypeId: "OrderHeader",
        })
      );
      await dispatch(
        getStatusList({ setStatus: setReturnStatus, statusTypeId: "Return" })
      );
      await dispatch(
        getStatusList({
          setStatus: setShipmentStatus,
          statusTypeId: "Shipment",
        })
      );
      await dispatch(
        getEnumTypeList({ setEnumType, enumTypeId: "SalesChannel" })
      );
      await dispatch(
        getEnumTypeList({ setEnumType: setOrderType, enumTypeId: "OrderType" })
      );
      await dispatch(
        getEnumTypeList({
          setEnumType: setShipMethodList,
          enumTypeId: "ShipmentMethod",
        })
      );
    };
    fetchData();
    return () => {
      setResetInputField(false);
      localStorage.removeItem("prevAppliedFilter");
    };
  }, [location.key]);
  const nextSetOfProds = async (
    pageIndex: any,
    orderByFields: any,
    appliedFilters: any,
    resetFields?: any,
    pageSize?: any
  ) => {
    setIsTableLoading(true);
    console.log("appliedFilters---198-----", appliedFilters);
    console.log("inside the nextSetOfProds", pageIndex);
    localStorage.setItem("currPageIndex", pageIndex);
    if (resetFields) {
      setResetInputField(true);
    } else {
      setResetInputField(false);
    }
    if (isSearch === "Searched") {
      await dispatch(
        getOrderListing({
          setgetOrderListingDetail,
          setGetBulkOrderListingDetail,
          setMaxPageIndex,
          orderByFields,
          setRecordCount,
          pageIndex,
          pageSize,
          queryString: searchableString,
          placedDate_from: selectedFromDate
            ? selectedFromDate
            : fromDate
            ? fromDate
            : "",
          placedDate_thru: selectedThruDate
            ? selectedThruDate
            : thruDate
            ? thruDate
            : "",
          statusId,
          facilityId: facilityId ? facilityId : "",
          onlyShipped,
          appliedFilters,
          advanceFindAppliedFilter,
          isReturnedOrders: isReturnedOrders ? true : false,
        })
      );
    } else {
      await dispatch(
        getOrderListing({
          setgetOrderListingDetail,
          setGetBulkOrderListingDetail,
          setMaxPageIndex,
          orderByFields,
          setRecordCount,
          pageIndex,
          pageSize,
          //searchString: orderStatus,
          placedDate_from: selectedFromDate
            ? selectedFromDate
            : fromDate
            ? fromDate
            : "",
          placedDate_thru: selectedThruDate
            ? selectedThruDate
            : thruDate
            ? thruDate
            : "",
          statusId,
          queryString,
          facilityId: facilityId ? facilityId : "",
          onlyShipped,
          appliedFilters,
          advanceFindAppliedFilter,
          isReturnedOrders: isReturnedOrders ? true : false,
        })
      );
    }
    setIsTableLoading(false);
  };
  orderStatus.forEach((item: any) => {
    statusFilters.push({
      text: item.value,
      value: item.key,
    });
  });
  shipmentStatus.forEach((item: any) => {
    shipmentstatusFilters.push({
      text: item.value,
      value: item.key,
    });
  });
  enumType.forEach((item: any) => {
    salesChannelFilters.push({
      text: item.value,
      value: item.key,
    });
  });
  shipMethodList.forEach((item: any) => {
    shipMethodFilters.push({
      text: item.value,
      value: item.key,
    });
  });
  const loadNextProds = (index: any, pageSize?: any) => {
    console.log("line-249----", index);
    if (!currIndex || currIndex * 1 === 0) {
      return;
    }
    if (currIndex * 1 > maxPageIndex) {
      return;
    }
    const passedIndex = index * 1 - 1;
    setCurrIndex(index);
    console.log("255", passedIndex);
    console.log("256", index);
    let prevAppliedFilter: any = localStorage.getItem("prevAppliedFilter");

    prevAppliedFilter = JSON.parse(prevAppliedFilter);
    let orderByFields = sortData;
    if (prevAppliedFilter?.orderByFields) {
      orderByFields = prevAppliedFilter?.orderByFields;
    }

    console.log("orderByFields in loadNextProds", orderByFields);
    if (pageSize > 10) {
      setPageSize(pageSize);
    }
    nextSetOfProds(
      passedIndex,
      orderByFields,
      activeAppliedFilters,
      false,
      pageSize
    );
  };
  const columns = [
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">ID</p>
        </div>
      ),
      dataIndex: "orderId",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: {
        setSelectedKeys: any;
        selectedKeys: any;
        confirm: any;
        clearFilters: any;
      }) => (
        <FilterSearchBar
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      filterIcon: () => {
        return filteredInfo.orderId ? (
          <AiOutlineFilter size={17} color="#093479" />
        ) : (
          <AiOutlineFilter size={17} />
        );
      },
      filteredValue: filteredInfo?.orderId || null,
      onFilter: (value: any, record: any) => record?.orderId.includes(value),
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.orderId === "number") {
            return a.orderId - b.orderId;
          } else {
            return a.orderId?.localeCompare(b.orderId);
          }
        },
        multiple: 1,
      },
      sortOrder: sortedInfo?.orderId === "orderId" && sortedInfo?.orderIdType,
      render: (orderId: string) => (
        <Link
          to={{
            pathname: `/order/${orderId}`,
            state: { orderId },
          }}
        >
          <h1 className="cursor-pointer underline text-primary">#{orderId}</h1>
        </Link>
      ),
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Display ID</p>
        </div>
      ),
      dataIndex: "displayId",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: {
        setSelectedKeys: any;
        selectedKeys: any;
        confirm: any;
        clearFilters: any;
      }) => (
        <FilterSearchBar
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      filteredValue: filteredInfo?.displayId || null,
      onFilter: (value: any, record: any) => record?.displayId?.includes(value),
      filterIcon: () => {
        return filteredInfo.displayId ? (
          <AiOutlineFilter size={17} color="#093479" />
        ) : (
          <AiOutlineFilter size={17} />
        );
      },
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.displayId === "number") {
            return a.displayId - b.displayId;
          } else {
            return a.displayId?.localeCompare(b.displayId);
          }
        },
        multiple: 1,
      },
      sortOrder:
        sortedInfo?.displayId === "displayId" && sortedInfo?.displayIdType,
      render: (displayId: string) => <h1 className="px-2">{displayId}</h1>,
    },
    // {
    //   title: (
    //     <div className="flex items-center relative h-5 p-2 leading-3">
    //       <p className="mr-1">Type</p>
    //     </div>
    //   ),
    //   dataIndex: "orderTypeEnumId",
    //   render: (orderTypeEnumId: string) => {
    //     const orderTypeEnum = orderType?.find(
    //       (type: any) => type?.key === orderTypeEnumId
    //     );
    //     return <h1 className="px-2">{orderTypeEnum?.value}</h1>;
    //   },
    // },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Store</p>
        </div>
      ),
      dataIndex: "productStoreId",
      filters: activeOrgStores?.map((item: any) => {
        return {
          value: item?.productStoreId,
          text: item?.storeName,
        };
      }),
      filterIcon: () => {
        return filteredInfo.productStoreId ? (
          <AiOutlineFilter size={17} color="#093479" />
        ) : (
          <AiOutlineFilter size={17} />
        );
      },
      // sorter: {
      //   compare: (a: any, b: any) => {
      //     if (typeof a.productStoreId === "number") {
      //       return a.productStoreId - b.productStoreId;
      //     } else {
      //       return a.productStoreId?.localeCompare(b.productStoreId);
      //     }
      //   },
      //   multiple: 1,
      // },
      filteredValue: filteredInfo?.productStoreId || null,
      // onFilter: (value: any, record: any) => {
      //   return false;
      //   console.log(record?.productStoreId.includes(value));
      //   // return record?.productStoreId.includes(value);
      //   return getBulkOrderListingDetail?.some((item: any) => {
      //     if (item?.productStoreId == value) {
      //       return true;
      //     }
      //   });
      // },
      // sortOrder:
      //   sortedInfo?.productStoreId === "productStoreId" &&
      //   sortedInfo?.productStoreIdType,
      render: (productStoreId: any, data: any) => {
        return (
          <div
            style={{
              wordWrap: "break-word",
              wordBreak: "break-word",
              whiteSpace: "nowrap",
            }}
          >
            <h1>{data?.storeName || ""}</h1>
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Sales Channel</p>
        </div>
      ),
      dataIndex: "salesChannelEnumId",
      filters: salesChannelFilters,
      filteredValue: filteredInfo?.salesChannelEnumId || null,
      onFilter: (value: any, record: any) =>
        record?.salesChannelEnumId?.includes(value),
      filterIcon: () => {
        return filteredInfo.salesChannelEnumId ? (
          <AiOutlineFilter size={17} color="#093479" />
        ) : (
          <AiOutlineFilter size={17} />
        );
      },
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.salesChannelEnumId === "number") {
            return a.salesChannelEnumId - b.salesChannelEnumId;
          } else {
            return a.salesChannelEnumId?.localeCompare(b.salesChannelEnumId);
          }
        },
        multiple: 1,
      },
      sortOrder:
        sortedInfo?.salesChannelEnumId === "salesChannelEnumId" &&
        sortedInfo?.salesChannelEnumIdType,
      render: (salesChannelEnumId: string, prod: any) => {
        return (
          <div>
            {enumType &&
              enumType.map((item: any) => {
                if (item.key === salesChannelEnumId) {
                  return <h1 className="px-2">{item.value}</h1>;
                }
                return null;
              })}
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Date Placed</p>
        </div>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: {
        setSelectedKeys: any;
        selectedKeys: any;
        confirm: any;
        clearFilters: any;
      }) => (
        <div style={{ padding: 8 }}>
          <div>
            <div className="sm:flex sm:flex-row sm:items-center">
              <DatePicker
                title="From"
                value={datePickerFrom}
                onChange={(date: any, dateString: any) => {
                  setDatePickerFrom(date);
                  setSelectedFromDate(dateString);
                  localStorage.setItem("orderListingFromDate", dateString);
                }}
              />
              {!isMobileDevice && <BsDash className="mt-5 mx-0.5" />}
              <div className="mt-3 sm:mt-0">
                <DatePicker
                  title="Thru"
                  value={datePickerThru}
                  onChange={(date: any, dateString: any) => {
                    setDatePickerThru(date);
                    setSelectedThruDate(dateString);
                    localStorage.setItem("orderListingThruDate", dateString);
                  }}
                />
              </div>
            </div>
            <div className="mt-3 flex flex-row items-center justify-between">
              <button
                onClick={async () => {
                  setIsTableLoading(true);
                  await dispatch(
                    getOrderListing({
                      setgetOrderListingDetail,
                      setGetBulkOrderListingDetail,
                      setMaxPageIndex,
                      orderByFields: sortData,
                      setRecordCount,
                      pageIndex: 0,
                      pageSize,
                      // searchString: orderStatus,
                      placedDate_from: fromDate ? fromDate : "",
                      placedDate_thru: thruDate ? thruDate : "",
                      statusId,
                      queryString,
                      facilityId: facilityId ? facilityId : "",
                      onlyShipped,
                      appliedFilters: activeAppliedFilters,
                      isReturnedOrders: isReturnedOrders ? true : false,
                    })
                  );
                  setResetInputField(true);
                  setSelectedFromDate("");
                  setSelectedThruDate("");
                  setDatePickerFrom("");
                  setDatePickerThru("");
                  localStorage.removeItem("orderListingThruDate");
                  localStorage.removeItem("orderListingFromDate");
                  filteredInfo["placedDate"] = null;
                  clearFilters();
                  setIsTableLoading(false);
                }}
                className="px-5 text-xs sm:px-3 sm:text-sm bg-transparent focus:outline-none border border-black text-black font-semibold py-1 rounded-lg"
              >
                Reset
              </button>
              <button
                onClick={async () => {
                  if (!selectedFromDate && !selectedThruDate) {
                    return;
                  }
                  setIsTableLoading(true);
                  document
                    .getElementsByClassName("ant-dropdown")[0]
                    .classList.add("ant-dropdown-hidden");
                  await dispatch(
                    getOrderListing({
                      setgetOrderListingDetail,
                      setGetBulkOrderListingDetail,
                      setMaxPageIndex,
                      orderByFields: sortData,
                      setRecordCount,
                      pageIndex: 0,
                      pageSize,
                      // searchString: orderStatus,
                      placedDate_from: selectedFromDate,
                      placedDate_thru: selectedThruDate,
                      statusId,
                      queryString,
                      facilityId: facilityId ? facilityId : "",
                      onlyShipped,
                      appliedFilters: activeAppliedFilters,
                      isReturnedOrders: isReturnedOrders ? true : false,
                    })
                  );
                  setResetInputField(true);
                  filteredInfo["placedDate"] = "placedDate";
                  setIsTableLoading(false);
                }}
                className="px-5 text-xs sm:px-3 sm:text-sm bg-primary focus:outline-none border text-white font-semibold py-1 rounded-lg"
              >
                Filter
              </button>
            </div>
          </div>
        </div>
      ),
      dataIndex: "placedDate",
      filterIcon: () => {
        return filteredInfo.placedDate ? (
          <AiOutlineFilter size={17} color="#093479" />
        ) : (
          <AiOutlineFilter size={17} />
        );
      },
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.placedDate === "number") {
            return a.placedDate - b.placedDate;
          } else {
            return a.placedDate?.localeCompare(b.placedDate);
          }
        },
        multiple: 1,
      },
      sortOrder:
        sortedInfo?.placedDate === "placedDate" && sortedInfo?.placedDateType,
      render: (placedDate: any) => {
        function dateCon(date: any) {
          const data = new Date(date).toDateString();
          return data;
        }
        return (
          <div className="flex flex-row items-center">
            {placedDate ? (
              <CalendarOutlined style={{ fontSize: 18, color: "#999" }} />
            ) : (
              ""
            )}

            <h1 className="ml-1.5">{placedDate ? dateCon(placedDate) : ""}</h1>
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Ship Date</p>
        </div>
      ),
      dataIndex: "shippingShipmentActualStartDate",
      // filters: shipMethodFilters,
      // filterIcon: () => {
      //   return filteredInfo.shippingShipmentActualStartDate ? (
      //     <AiOutlineFilter size={17} color="#093479" />
      //   ) : (
      //     <AiOutlineFilter size={17} />
      //   );
      // },
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.shippingShipmentActualStartDate === "number") {
            return (
              a.shippingShipmentActualStartDate -
              b.shippingShipmentActualStartDate
            );
          } else {
            return a.shippingShipmentActualStartDate?.localeCompare(
              b.shippingShipmentActualStartDate
            );
          }
        },
        multiple: 1,
      },
      // filteredValue: filteredInfo.shippingShipmentActualStartDate || null,
      // onFilter: (value: any, record: any) => {
      //   let isShipmentEnumIdMatched = false;
      //   record?.items &&
      //     record?.items?.length > 0 &&
      //     record?.items?.[0]?.shipmentSources &&
      //     record?.items?.[0]?.shipmentSources?.length > 0 &&
      //     record?.items?.[0]?.shipmentSources?.[0]?.routeSegments &&
      //     record?.items?.[0]?.shipmentSources?.[0]?.routeSegments?.length > 0 &&
      //     record?.items?.[0]?.shipmentSources?.[0]?.routeSegments?.forEach(
      //       (item: any) => {
      //         if (
      //           item.shippingShipmentActualStartDate &&
      //           item.shippingShipmentActualStartDate.includes(value)
      //         ) {
      //           isShipmentEnumIdMatched = true;
      //         }
      //       }
      //     );
      //   // record?.parts &&
      //   //   record?.parts.length > 0 &&
      //   //   record?.parts.forEach((item: any) => {
      //   //     if (
      //   //       item.shipmentMethodEnumId &&
      //   //       item.shipmentMethodEnumId.includes(value)
      //   //     ) {
      //   //       isShipmentEnumIdMatched = true;
      //   //     }
      //   //   });
      //   return isShipmentEnumIdMatched;
      // },
      sortOrder:
        sortedInfo.shippingShipmentActualStartDate ===
          "shippingShipmentActualStartDate" &&
        sortedInfo.shippingShipmentActualStartDateType,
      render: (shipmentActualDate: string, order: any) => {
        let shipmentActualStartDate = "";
        if (
          order?.items &&
          order?.items?.length > 0 &&
          order?.items?.[0]?.shipmentSources &&
          order?.items?.[0]?.shipmentSources?.length > 0 &&
          order?.items?.[0]?.shipmentSources?.[0]?.routeSegments &&
          order?.items?.[0]?.shipmentSources?.[0]?.routeSegments?.length > 0
        ) {
          order?.items?.[0]?.shipmentSources?.[0]?.routeSegments?.forEach(
            (item: any) => {
              if (item.shippingShipmentActualStartDate) {
                shipmentActualStartDate = item.shippingShipmentActualStartDate;
              }
            }
          );
        }
        function dateCon(date: any) {
          const data = new Date(date).toDateString();
          return data;
        }
        return (
          <div
            className="flex flex-row items-center"
            style={{
              wordWrap: "break-word",
              wordBreak: "break-word",
              whiteSpace: "nowrap",
            }}
          >
            {shipmentActualStartDate ? (
              <CalendarOutlined style={{ fontSize: 18, color: "#999" }} />
            ) : (
              ""
            )}

            <h1 className="ml-1.5">
              {shipmentActualStartDate ? dateCon(shipmentActualStartDate) : ""}
            </h1>
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Customer</p>
        </div>
      ),
      dataIndex: "parts.customerName",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: {
        setSelectedKeys: any;
        selectedKeys: any;
        confirm: any;
        clearFilters: any;
      }) => (
        <FilterSearchBar
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      filterIcon: () => {
        return filteredInfo["parts.customerName"] ? (
          <AiOutlineFilter size={17} color="#093479" />
        ) : (
          <AiOutlineFilter size={17} />
        );
      },
      filteredValue: filteredInfo["parts.customerName"] || null,
      onFilter: (value: any, record: any) => {
        let isCustomerMatched = false;
        record?.parts &&
          record?.parts.length > 0 &&
          record?.parts.forEach((item: any) => {
            if (
              item.customerName &&
              item.customerName.toLowerCase().includes(value.toLowerCase())
            ) {
              isCustomerMatched = true;
            }
          });
        return isCustomerMatched;
      },
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.customerName === "number") {
            return a.customerName - b.customerName;
          } else {
            return a.customerName?.localeCompare(b.customerName);
          }
        },
        multiple: 1,
      },
      sortOrder:
        sortedInfo["parts.customerName"] === "parts.customerName" &&
        sortedInfo["parts.customerNameType"],
      render: (customerName: string, order: any) => {
        let customer_name = "";
        if (order.parts && order.parts.length > 0) {
          order.parts.forEach((item: any) => {
            if (item.customerName) {
              customer_name = item.customerName;
            }
          });
        }
        return (
          <div className="flex flex-row items-center">
            <div className="flex flex-row">
              <UserOutlined style={{ fontSize: 18 }} />
              <h1 className="ml-2">{customer_name}</h1>
            </div>
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Country</p>
        </div>
      ),
      dataIndex: "parts.countryGeoId",
      filters: countriesList?.map((item: any) => {
        return {
          value: item?.key,
          text: item?.value,
        };
      }),
      filterIcon: () => {
        if (advanceFindAppliedFilter.countryId) {
          return null;
        } else {
          return filteredInfo["parts.countryGeoId"] ? (
            <AiOutlineFilter size={17} color="#093479" />
          ) : (
            <AiOutlineFilter size={17} />
          );
        }
      },
      filteredValue: filteredInfo["parts.countryGeoId"] || null,
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.countryName === "number") {
            return a.countryName - b.countryName;
          } else {
            return a.countryName?.localeCompare(b.countryName);
          }
        },
        multiple: 1,
      },
      sortOrder:
        sortedInfo["parts.countryGeoId"] === "parts.countryGeoId" &&
        sortedInfo["parts.countryGeoIdType"],
      render: (countryName: string, order: any) => {
        let country = "";
        if (order.parts && order.parts.length > 0) {
          order.parts.forEach((item: any) => {
            if (item?.countryName) {
              country = item?.countryName;
            } else {
              country = "NA";
            }
          });
        }
        return <h1 className="ml-2">{country}</h1>;
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">State</p>
        </div>
      ),
      dataIndex: "parts.stateProvinceGeoId",
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.stateName === "number") {
            return a.stateName - b.stateName;
          } else {
            return a.stateName?.localeCompare(b.stateName);
          }
        },
        multiple: 1,
      },
      sortOrder:
        sortedInfo["parts.stateProvinceGeoId"] === "parts.stateProvinceGeoId" &&
        sortedInfo["parts.stateProvinceGeoIdType"],
      render: (stateName: string, order: any) => {
        let state = "";
        if (order.parts && order.parts.length > 0) {
          order.parts.forEach((item: any) => {
            if (item.stateName) {
              state = item.stateName;
            } else {
              state = "NA";
            }
          });
        }
        return <h1 className="ml-2">{state}</h1>;
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Order Status</p>
        </div>
      ),
      dataIndex: "statusId",
      filters: statusFilters,
      filterIcon: () => {
        return filteredInfo.statusId ? (
          <AiOutlineFilter size={17} color="#093479" />
        ) : (
          <AiOutlineFilter size={17} />
        );
      },
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.statusId === "number") {
            return a.statusId - b.statusId;
          } else {
            return a.statusId?.localeCompare(b.statusId);
          }
        },
        multiple: 1,
      },
      filteredValue: filteredInfo?.statusId || null,
      onFilter: (value: any, record: any) => record?.statusId.includes(value),
      sortOrder:
        sortedInfo?.statusId === "statusId" && sortedInfo?.statusIdType,
      render: (statusId: any, data: any) => {
        return (
          <div>
            {orderStatus &&
              orderStatus.map((item: any) => {
                if (item.key === statusId) {
                  return (
                    <>
                      <h1
                        style={{
                          backgroundColor: "#093479",
                          outlineColor: "#707070",
                          border: "1px solid #707070",
                        }}
                        className="px-1.5 py-0.5 text-center text-white tracking-wider border font-semibold rounded-md w-max"
                      >
                        {item?.value?.toUpperCase()}
                      </h1>
                    </>
                  );
                }
                return null;
              })}
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Shipment ID</p>
        </div>
      ),
      dataIndex: "shipmentId",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: {
        setSelectedKeys: any;
        selectedKeys: any;
        confirm: any;
        clearFilters: any;
      }) => (
        <FilterSearchBar
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      filterIcon: () => {
        return filteredInfo.shipmentId ? (
          <AiOutlineFilter size={17} color="#093479" />
        ) : (
          <AiOutlineFilter size={17} />
        );
      },
      filteredValue: filteredInfo?.shipmentId || null,
      onFilter: (value: any, record: any) => {
        return record?.items?.[0]?.shipmentSources?.filter((sources: any) =>
          sources?.shipmentId?.includes(value)
        );
      },
      render: (shipmentID: any, data: any) => {
        return data?.items?.[0]?.shipmentSources?.map((item: any) => {
          return <h1 className="px-2">{item?.shipmentId}</h1>;
        });
      },
      sorter: {
        compare: (currIndex: any, nextIndex: any) => {
          const currIndexShipmentIdList: any =
            currIndex?.items?.[0]?.shipmentSources?.map(
              (source: any) => source?.shipmentId
            ) || [];
          const nextIndexShipmentIdList: any =
            nextIndex?.items?.[0]?.shipmentSources?.map(
              (source: any) => source?.shipmentId
            ) || [];
          const currIndexMinShipmentId: any = Math.min(
            ...currIndexShipmentIdList
          );
          const nextIndexMinShipmentId: any = Math.min(
            ...nextIndexShipmentIdList
          );
          if (typeof currIndexMinShipmentId === "number") {
            return currIndexMinShipmentId - nextIndexMinShipmentId;
          } else {
            return currIndexMinShipmentId?.localeCompare(
              nextIndexMinShipmentId
            );
          }
        },
        multiple: 1,
      },
      sortOrder:
        sortedInfo?.shipmentId === "shipmentId" && sortedInfo?.shipmentIdType,
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Shipment Status</p>
        </div>
      ),
      dataIndex: "shipmentStatusId",
      render: (shipmentSources: any, data: any) => {
        const shipmentSourcesList: any =
          data?.items?.[0]?.shipmentSources || [];

        return (
          <div>
            {shipmentSourcesList.map((source: any, index: number) => {
              const shipmentStatusId = source?.shipmentStatusId;

              return (
                <div key={index} className="mb-2">
                  {shipmentStatus &&
                    shipmentStatus.map((item: any) => {
                      if (item.key === shipmentStatusId) {
                        return (
                          <h1
                            key={item.key}
                            style={{
                              backgroundColor: "#093479",
                              outlineColor: "#707070",
                              border: "1px solid #707070",
                            }}
                            className="px-1.5 py-0.5 ml-2 text-center text-white tracking-wider border font-semibold rounded-md w-max"
                          >
                            {item?.value?.toUpperCase()}
                          </h1>
                        );
                      }
                      return null;
                    })}
                </div>
              );
            })}
          </div>
        );
      },
      filters: shipmentstatusFilters,
      filterIcon: () => {
        return filteredInfo.shipmentStatusId ? (
          <AiOutlineFilter size={17} color="#093479" />
        ) : (
          <AiOutlineFilter size={17} />
        );
      },
      filteredValue: filteredInfo?.shipmentStatusId || null,
      onFilter: (value: any, record: any) => {
        return record?.items?.[0]?.shipmentSources?.some((source: any) =>
          source?.shipmentStatusId?.includes(value)
        );
      },
      sorter: {
        compare: (currIndex: any, nextIndex: any) => {
          const currIndexShipmentStatusList: any =
            currIndex?.items?.[0]?.shipmentSources?.map(
              (source: any) => source?.shipmentStatusId
            ) || [];
          const nextIndexShipmentStatusList: any =
            nextIndex?.items?.[0]?.shipmentSources?.map(
              (source: any) => source?.shipmentStatusId
            ) || [];

          const currIndexMinStatusId: any = Math.min(
            ...currIndexShipmentStatusList
          );
          const nextIndexMinStatusId: any = Math.min(
            ...nextIndexShipmentStatusList
          );

          if (typeof currIndexMinStatusId === "number") {
            return currIndexMinStatusId - nextIndexMinStatusId;
          } else {
            return currIndexMinStatusId?.localeCompare(nextIndexMinStatusId);
          }
        },
        multiple: 1,
      },
      sortOrder:
        sortedInfo?.shipmentStatusId === "shipmentStatusId" &&
        sortedInfo?.shipmentStatusIdType,
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <h1 className="mr-1">Returned?</h1>
        </div>
      ),
      dataIndex: "statusId",
      render: (statusId: any, data: any) => {
        return (
          <div>
            {data?.items?.find((record: any) => record?.returnHeader) && (
              <h1
                style={{
                  backgroundColor: "#EE2157",
                  outlineColor: "#707070",
                  border: "1px solid #EE2157",
                }}
                className="px-1.5 py-0.5 text-center text-white tracking-wider border font-semibold rounded-md w-max"
              >
                RETURNED
              </h1>
            )}
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Total</p>
        </div>
      ),
      dataIndex: "grandTotal",
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.grandTotal === "number") {
            return a.grandTotal - b.grandTotal;
          } else {
            return a.grandTotal?.localeCompare(b.grandTotal);
          }
        },
        multiple: 1,
      },
      sortOrder:
        sortedInfo?.grandTotal === "grandTotal" && sortedInfo?.grandTotalType,
      render: (grandTotal: any) => (
        <h1>${Number(grandTotal ?? 0).toFixed(2)}</h1>
      ),
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Ship Method</p>
        </div>
      ),
      dataIndex: "shippingShipmentMethodEnumId",
      filters: shipMethodFilters,
      filterIcon: () => {
        return filteredInfo.shippingShipmentMethodEnumId ? (
          <AiOutlineFilter size={17} color="#093479" />
        ) : (
          <AiOutlineFilter size={17} />
        );
      },
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.shippingShipmentMethodEnumId === "number") {
            return (
              a.shippingShipmentMethodEnumId - b.shippingShipmentMethodEnumId
            );
          } else {
            return a.shippingShipmentMethodEnumId?.localeCompare(
              b.shippingShipmentMethodEnumId
            );
          }
        },
        multiple: 1,
      },
      filteredValue: filteredInfo.shippingShipmentMethodEnumId || null,
      onFilter: (value: any, record: any) => {
        let isShipmentEnumIdMatched = false;
        record?.items &&
          record?.items?.length > 0 &&
          record?.items?.[0]?.shipmentSources &&
          record?.items?.[0]?.shipmentSources?.length > 0 &&
          record?.items?.[0]?.shipmentSources?.[0]?.routeSegments &&
          record?.items?.[0]?.shipmentSources?.[0]?.routeSegments?.length > 0 &&
          record?.items?.[0]?.shipmentSources?.[0]?.routeSegments?.forEach(
            (item: any) => {
              if (
                item.shippingShipmentMethodEnumId &&
                item.shippingShipmentMethodEnumId.includes(value)
              ) {
                isShipmentEnumIdMatched = true;
              }
            }
          );
        // record?.parts &&
        //   record?.parts.length > 0 &&
        //   record?.parts.forEach((item: any) => {
        //     if (
        //       item.shipmentMethodEnumId &&
        //       item.shipmentMethodEnumId.includes(value)
        //     ) {
        //       isShipmentEnumIdMatched = true;
        //     }
        //   });
        return isShipmentEnumIdMatched;
      },
      sortOrder:
        sortedInfo.shippingShipmentMethodEnumId ===
          "shippingShipmentMethodEnumId" &&
        sortedInfo.shippingShipmentMethodEnumIdType,
      render: (shipMethod: string, order: any) => {
        let shipmentMethodEnumId = "";
        if (
          order?.items &&
          order?.items?.length > 0 &&
          order?.items?.[0]?.shipmentSources &&
          order?.items?.[0]?.shipmentSources?.length > 0 &&
          order?.items?.[0]?.shipmentSources?.[0]?.routeSegments &&
          order?.items?.[0]?.shipmentSources?.[0]?.routeSegments?.length > 0
        ) {
          order?.items?.[0]?.shipmentSources?.[0]?.routeSegments?.forEach(
            (item: any) => {
              if (item.shippingShipmentMethodEnumId) {
                shipmentMethodEnumId = item.shippingShipmentMethodEnumId;
              }
            }
          );
        }
        // if (order.parts && order.parts.length > 0) {
        //   order.parts.forEach((item: any) => {
        //     if (item.shipmentMethodEnumId) {
        //       shipmentMethodEnumId = item.shipmentMethodEnumId;
        //     }
        //   });
        // }
        return (
          <div>
            {shipMethodList &&
              shipMethodList.map((item: any) => {
                if (item.key === shipmentMethodEnumId) {
                  return <h1 className="px-2">{item.value}</h1>;
                }
                return null;
              })}
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Tracking Code</p>
        </div>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: {
        setSelectedKeys: any;
        selectedKeys: any;
        confirm: any;
        clearFilters: any;
      }) => (
        <FilterSearchBar
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      filterIcon: () => {
        return filteredInfo.trackingCode ? (
          <AiOutlineFilter size={17} color="#093479" />
        ) : (
          <AiOutlineFilter size={17} />
        );
      },
      filteredValue: filteredInfo?.trackingCode || null,
      onFilter: (value: any, record: any) => {
        let shipmentSources: any[] = record?.items?.[0]?.shipmentSources ?? [];
        return shipmentSources.some((shipmentSource: any) =>
          shipmentSource?.packageRouteSegments?.some((segment: any) =>
            segment?.shipmentTrackingCode?.includes(value)
          )
        );
      },
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.trackingCode === "number") {
            return a.trackingCode - b.trackingCode;
          } else {
            return a.trackingCode?.localeCompare(b.trackingCode);
          }
        },
        multiple: 1,
      },
      sortOrder:
        sortedInfo?.trackingCode === "trackingCode" &&
        sortedInfo?.trackingCodeType,
      dataIndex: "trackingCode",
      render: (items: any, data: any) => {
        let shipmentSources: any = data?.items?.[0]?.shipmentSources;
        let trackingCode: string[] = [];
        shipmentSources &&
          shipmentSources?.forEach((item: any) => {
            item?.packageRouteSegments?.forEach((data: any, index: any) => {
              trackingCode.push(data?.shipmentTrackingCode);
            });
          });
        return (
          <div style={{ whiteSpace: "pre-wrap" }}>
            <h1>{trackingCode.join(", ")}</h1>
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Return Info (ReturnID | RMA | Tracking)</p>
        </div>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: {
        setSelectedKeys: any;
        selectedKeys: any;
        confirm: any;
        clearFilters: any;
      }) => (
        <FilterSearchBar
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      filterIcon: () => {
        return filteredInfo.returnDetails ? (
          <AiOutlineFilter size={17} color="#093479" />
        ) : (
          <AiOutlineFilter size={17} />
        );
      },
      filteredValue: filteredInfo?.returnDetails || null,
      onFilter: (value: any, record: any) =>
        record?.items?.some((data: any) =>
          data?.returnHeader?.some(
            (item: any) =>
              item?.returnId?.includes(value) ||
              item?.externalId?.includes(value) ||
              item?.shipmentSources?.[0]?.packageRouteSegments?.[0]?.trackingCode?.includes(
                value
              )
          )
        ),
      dataIndex: "returnDetails",
      render: (returnDetails: any, data: any) => {
        let dataContent: string = "";

        data?.items?.forEach((item: any, index: number) => {
          item?.returnHeader?.forEach((header: any, headerIndex: number) => {
            const { returnId, externalId } = header;
            dataContent += `${returnId || ""}${
              externalId ? ` | ${externalId}` : ""
            }`;

            if (header?.shipmentSources) {
              header.shipmentSources?.forEach((source: any) => {
                if (source?.packageRouteSegments) {
                  source.packageRouteSegments?.forEach(
                    (segment: any, segmentIndex: number) => {
                      dataContent += `${
                        segment.trackingCode ? ` | ${segment.trackingCode}` : ""
                      }`;
                    }
                  );
                }
              });
            }
            if (
              !(
                index === data?.items.length - 1 &&
                headerIndex === item.returnHeader.length - 1
              )
            ) {
              dataContent += ", \n";
            }
          });
        });
        return (
          <h1 className="ml-2" style={{ whiteSpace: "break-spaces" }}>
            {dataContent}
          </h1>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Segmentation</p>
        </div>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: {
        setSelectedKeys: any;
        selectedKeys: any;
        confirm: any;
        clearFilters: any;
      }) => (
        <FilterSearchBar
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      filterIcon: () => {
        return filteredInfo.segmentation ? (
          <AiOutlineFilter size={17} color="#093479" />
        ) : (
          <AiOutlineFilter size={17} />
        );
      },
      filteredValue: filteredInfo?.segmentation || null,
      onFilter: (value: any, record: any) =>
        record?.segmentation?.includes(value),
      dataIndex: "segmentation",
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.segmentation === "number") {
            return a.grandsegmentationTotal - b.segmentation;
          } else {
            return a.segmentation?.localeCompare(b.segmentation);
          }
        },
        multiple: 1,
      },
      sortOrder:
        sortedInfo?.segmentation === "segmentation" &&
        sortedInfo?.segmentationType,
      render: (segmentation: any) => {
        return <h1 className="ml-2">{segmentation}</h1>;
      },
    },
  ];
  function onChange(pagination: any, filters: any, sorter: any, extra: any) {
    let appliedFilters = "";
    if (filters.orderId) {
      if (!appliedFilters) {
        appliedFilters += `orderId=${filters.orderId}`;
      } else {
        appliedFilters += `&orderId=${filters.orderId}`;
      }
    }
    if (filters.segmentation) {
      if (!appliedFilters) {
        appliedFilters += `segmentation=${filters.segmentation}`;
      } else {
        appliedFilters += `&segmentation=${filters.segmentation}`;
      }
    }
    if (filters.trackingCode) {
      if (!appliedFilters) {
        appliedFilters += `shipmentTrackingCode=${filters.trackingCode}`;
      } else {
        appliedFilters += `&shipmentTrackingCode=${filters.trackingCode}`;
      }
    }
    if (filters.returnDetails) {
      if (!appliedFilters) {
        appliedFilters += `returnInfo=${filters.returnDetails}`;
      } else {
        appliedFilters += `&returnInfo=${filters.returnDetails}`;
      }
    }
    if (filters.shipmentId) {
      if (!appliedFilters) {
        appliedFilters += `shipmentId=${filters.shipmentId}`;
      } else {
        appliedFilters += `&shipmentId=${filters.shipmentId}`;
      }
    }
    if (filters.displayId) {
      if (!appliedFilters) {
        appliedFilters += `displayId=${filters.displayId}`;
      } else {
        appliedFilters += `&displayId=${filters.displayId}`;
      }
    }
    // if (filters.shippingShipmentMethodEnumId) {
    //   if (!appliedFilters) {
    //     appliedFilters += `shippingShipmentMethodEnumId=${filters.shippingShipmentMethodEnumId}`;
    //   } else {
    //     appliedFilters += `&shippingShipmentMethodEnumId=${filters.shippingShipmentMethodEnumId}`;
    //   }
    // }
    if (filters["parts.customerName"]) {
      if (!appliedFilters) {
        appliedFilters += `customerName=${filters["parts.customerName"]}`;
      } else {
        appliedFilters += `&customerName=${filters["parts.customerName"]}`;
      }
    }
    // if (filters.productStoreId) {
    //   if (!appliedFilters) {
    //     if (filters?.productStoreId.length > 0) {
    //       const joinedStr = filters?.productStoreId.join(" OR ");
    //       appliedFilters += `queryString=productStoreId: ${joinedStr}`;
    //     }
    //   } else {
    //     if (filters?.productStoreId.length > 0) {
    //       const filtersArr = appliedFilters.split("&");
    //       const index = filtersArr.findIndex((item: any) =>
    //         item.includes("queryString")
    //       );
    //       const joinedStr = filters?.productStoreId.join(" OR ");
    //       if (index > -1) {
    //         let extractedQuery = filtersArr[index];
    //         extractedQuery += ` AND productStoreId: ${joinedStr}`;
    //         filtersArr[index] = extractedQuery;
    //         const joinedFilters = filtersArr.join("&");
    //         appliedFilters = joinedFilters;
    //       } else {
    //         appliedFilters += `&queryString=productStoreId: ${joinedStr}`;
    //       }
    //     }
    //   }
    // }
    if (filters.productStoreId) {
      if (!appliedFilters) {
        appliedFilters += `productStoreId=${filters?.productStoreId?.join(
          ","
        )}`;
      } else {
        appliedFilters += `&productStoreId=${filters?.productStoreId?.join(
          ","
        )}`;
      }
    }
    if (filters["parts.countryGeoId"]) {
      if (!appliedFilters) {
        appliedFilters += `countryId=${filters["parts.countryGeoId"]?.join(
          ","
        )}`;
      } else {
        appliedFilters += `&countryId=${filters["parts.countryGeoId"]?.join(
          ","
        )}`;
      }
    }
    if (filters.shipmentStatusId) {
      if (!appliedFilters) {
        if (filters.shipmentStatusId.length > 0) {
          const joinedStr = filters.shipmentStatusId.join(" OR ");
          appliedFilters += `queryString=items.shipmentSources.shipmentStatusId: ${joinedStr}`;
        }
      } else {
        if (filters.shipmentStatusId.length > 0) {
          const filtersArr = appliedFilters.split("&");
          const index = filtersArr.findIndex((item: any) =>
            item.includes("queryString")
          );
          const joinedStr = filters.shipmentStatusId.join(" OR ");
          if (index > -1) {
            let extractedQuery = filtersArr[index];
            extractedQuery += ` AND items.shipmentSources.shipmentStatusId: ${joinedStr}`;
            filtersArr[index] = extractedQuery;
            const joinedFilters = filtersArr.join("&");
            appliedFilters = joinedFilters;
          } else {
            appliedFilters += `&queryString=items.shipmentSources.shipmentStatusId: ${joinedStr}`;
          }
        }
      }
    }
    if (filters.statusId) {
      if (!appliedFilters) {
        if (filters?.statusId.length > 0) {
          const joinedStr = filters?.statusId.join(" OR ");
          appliedFilters += `queryString=statusId: ${joinedStr}`;
        }
      } else {
        if (filters?.statusId.length > 0) {
          const filtersArr = appliedFilters.split("&");
          const index = filtersArr.findIndex((item: any) =>
            item.includes("queryString")
          );
          const joinedStr = filters?.statusId.join(" OR ");
          if (index > -1) {
            let extractedQuery = filtersArr[index];
            extractedQuery += ` AND statusId: ${joinedStr}`;
            filtersArr[index] = extractedQuery;
            const joinedFilters = filtersArr.join("&");
            appliedFilters = joinedFilters;
          } else {
            appliedFilters += `&queryString=statusId: ${joinedStr}`;
          }
        }
      }
    }
    if (filters.salesChannelEnumId) {
      if (!appliedFilters) {
        if (filters?.salesChannelEnumId.length > 0) {
          const joinedStr = filters?.salesChannelEnumId.join(" OR ");
          appliedFilters += `queryString=salesChannelEnumId: ${joinedStr}`;
        }
      } else {
        if (filters?.salesChannelEnumId.length > 0) {
          const filtersArr = appliedFilters.split("&");
          const index = filtersArr.findIndex((item: any) =>
            item.includes("queryString")
          );
          const joinedStr = filters?.salesChannelEnumId.join(" OR ");
          if (index > -1) {
            let extractedQuery = filtersArr[index];
            extractedQuery += ` AND salesChannelEnumId: ${joinedStr}`;
            filtersArr[index] = extractedQuery;
            const joinedFilters = filtersArr.join("&");
            appliedFilters = joinedFilters;
          } else {
            appliedFilters += `&queryString=salesChannelEnumId: ${joinedStr}`;
          }
        }
      }
    }
    if (filters.shippingShipmentMethodEnumId) {
      if (!appliedFilters) {
        if (filters.shippingShipmentMethodEnumId.length > 0) {
          const joinedStr = filters.shippingShipmentMethodEnumId.join(" OR ");
          appliedFilters += `queryString=items.shipmentSources.routeSegments.shippingShipmentMethodEnumId: ${joinedStr}`;
        }
      } else {
        if (filters.shippingShipmentMethodEnumId.length > 0) {
          const filtersArr = appliedFilters.split("&");
          const index = filtersArr.findIndex((item: any) =>
            item.includes("queryString")
          );
          const joinedStr = filters.shippingShipmentMethodEnumId.join(" OR ");
          if (index > -1) {
            let extractedQuery = filtersArr[index];
            extractedQuery += ` AND items.shipmentSources.routeSegments.shippingShipmentMethodEnumId: ${joinedStr}`;
            filtersArr[index] = extractedQuery;
            const joinedFilters = filtersArr.join("&");
            appliedFilters = joinedFilters;
          } else {
            appliedFilters += `&queryString=items.shipmentSources.routeSegments.shippingShipmentMethodEnumId: ${joinedStr}`;
          }
        }
      }
    }
    setFilteredInfo(filters);
    setActiveAppliedFilters(appliedFilters);
    let outputStr = "";
    setSortData("");
    if (Object.keys(sorter)?.length > 0) {
      setSortedInfo({
        [sorter?.field]: sorter?.field,
        [`${sorter?.field}Type`]: sorter?.order,
      });
      Object.keys(sorter).forEach((item: any) => {
        if (item === "order" && sorter[item] === "ascend") {
          if (outputStr) {
            if (outputStr.includes(sorter["field"])) {
              // do nothing
            } else {
              if (sorter["field"] === "shippingShipmentMethodEnumId") {
                outputStr += `,items.shipmentSources.routeSegments.shippingShipmentMethodEnumId`;
              } else if (
                sorter["field"] === "shippingShipmentActualStartDate"
              ) {
                outputStr += `,items.shipmentSources.routeSegments.shippingShipmentActualStartDate`;
              } else if (sorter["field"] === "shipmentId") {
                outputStr += `,items.shipmentSources.shipmentId`;
              } else if (sorter["field"] === "shipmentStatusId") {
                outputStr += `,items.shipmentSources.shipmentStatusId`;
              } else if (sorter["field"] === "trackingCode") {
                outputStr += `,items.shipmentSources.packageRouteSegments.shipmentTrackingCode`;
              } else {
                outputStr += `,${sorter["field"]}`;
              }
            }
          } else {
            if (sorter["field"] === "shippingShipmentMethodEnumId") {
              outputStr = `items.shipmentSources.routeSegments.shippingShipmentMethodEnumId`;
            } else if (sorter["field"] === "shippingShipmentActualStartDate") {
              outputStr += `items.shipmentSources.routeSegments.shippingShipmentActualStartDate`;
            } else if (sorter["field"] === "shipmentId") {
              outputStr += `items.shipmentSources.shipmentId`;
            } else if (sorter["field"] === "shipmentStatusId") {
              outputStr += `items.shipmentSources.shipmentStatusId`;
            } else if (sorter["field"] === "trackingCode") {
              outputStr += `items.shipmentSources.packageRouteSegments.shipmentTrackingCode`;
            } else {
              outputStr = `${sorter["field"]}`;
            }
          }
        } else if (item === "order" && sorter[item] === "descend") {
          if (outputStr) {
            if (outputStr.includes(sorter["field"])) {
              // do nothing
            } else {
              if (sorter["field"] === "shippingShipmentMethodEnumId") {
                outputStr += `,-items.shipmentSources.routeSegments.shippingShipmentMethodEnumId`;
              } else if (sorter["field"] === "shipmentId") {
                outputStr += `,-items.shipmentSources.shipmentId`;
              } else if (sorter["field"] === "shipmentStatusId") {
                outputStr += `,-items.shipmentSources.shipmentStatusId`;
              } else if (
                sorter["field"] === "shippingShipmentActualStartDate"
              ) {
                outputStr += `,-items.shipmentSources.routeSegments.shippingShipmentActualStartDate`;
              } else if (sorter["field"] === "trackingCode") {
                outputStr += `,-items.shipmentSources.packageRouteSegments.shipmentTrackingCode`;
              } else {
                outputStr += `,-${sorter["field"]}`;
              }
            }
          } else {
            if (sorter["field"] === "shippingShipmentMethodEnumId") {
              outputStr = `-items.shipmentSources.routeSegments.shippingShipmentMethodEnumId`;
            } else if (sorter["field"] === "shippingShipmentActualStartDate") {
              outputStr += `-items.shipmentSources.routeSegments.shippingShipmentActualStartDate`;
            } else if (sorter["field"] === "shipmentId") {
              outputStr += `-items.shipmentSources.shipmentId`;
            } else if (sorter["field"] === "shipmentStatusId") {
              outputStr += `-items.shipmentSources.shipmentStatusId`;
            } else if (sorter["field"] === "trackingCode") {
              outputStr += `-items.shipmentSources.packageRouteSegments.shipmentTrackingCode`;
            } else {
              outputStr = `-${sorter["field"]}`;
            }
          }
        }
      });
    }
    if (Array.isArray(sorter)) {
      console.log("inside the 884 condition ", sorter);
      let modifiedObj = {};
      sorter.forEach((item: any) => {
        modifiedObj = {
          ...modifiedObj,
          [item?.field]: item?.field,
          [`${item?.field}Type`]: item?.order,
        };
      });
      console.log("modifiedObj------->", modifiedObj);
      setSortedInfo(modifiedObj);
    }
    sorter &&
      sorter.length > 0 &&
      sorter?.forEach((item: any) => {
        if (item.order === "ascend") {
          if (outputStr) {
            if (outputStr.includes(item["field"])) {
              // do nothing
            } else {
              if (item["field"] === "shippingShipmentMethodEnumId") {
                outputStr += `,items.shipmentSources.routeSegments.shippingShipmentMethodEnumId`;
              } else if (item["field"] === "shippingShipmentActualStartDate") {
                outputStr += `,items.shipmentSources.routeSegments.shippingShipmentActualStartDate`;
              } else if (item["field"] === "shipmentId") {
                outputStr += `,items.shipmentSources.shipmentId`;
              } else if (item["field"] === "shipmentStatusId") {
                outputStr += `,items.shipmentSources.shipmentStatusId`;
              } else if (item["field"] === "trackingCode") {
                outputStr += `,items.shipmentSources.packageRouteSegments.shipmentTrackingCode`;
              } else {
                outputStr += `,${item.field}`;
              }
            }
          } else {
            if (item["field"] === "shippingShipmentMethodEnumId") {
              outputStr = `items.shipmentSources.routeSegments.shippingShipmentMethodEnumId`;
            } else if (item["field"] === "shippingShipmentActualStartDate") {
              outputStr += `items.shipmentSources.routeSegments.shippingShipmentActualStartDate`;
            } else if (item["field"] === "shipmentId") {
              outputStr += `items.shipmentSources.shipmentId`;
            } else if (item["field"] === "shipmentStatusId") {
              outputStr += `items.shipmentSources.shipmentStatusId`;
            } else if (item["field"] === "trackingCode") {
              outputStr += `items.shipmentSources.packageRouteSegments.shipmentTrackingCode`;
            } else {
              outputStr = `${item.field}`;
            }
          }
        } else {
          if (outputStr) {
            if (outputStr.includes(item["field"])) {
              // do nothing
            } else {
              if (item["field"] === "shippingShipmentMethodEnumId") {
                outputStr += `,-items.shipmentSources.routeSegments.shippingShipmentMethodEnumId`;
              } else if (item["field"] === "shippingShipmentActualStartDate") {
                outputStr += `,-items.shipmentSources.routeSegments.shippingShipmentActualStartDate`;
              } else if (item["field"] === "shipmentId") {
                outputStr += `,-items.shipmentSources.shipmentId`;
              } else if (item["field"] === "shipmentStatusId") {
                outputStr += `,-items.shipmentSources.shipmentStatusId`;
              } else if (item["field"] === "trackingCode") {
                outputStr += `,-items.shipmentSources.packageRouteSegments.shipmentTrackingCode`;
              } else {
                outputStr += `,-${item.field}`;
              }
            }
          } else {
            if (item["field"] === "shippingShipmentMethodEnumId") {
              outputStr = `-items.shipmentSources.routeSegments.shippingShipmentMethodEnumId`;
            } else if (item["field"] === "shippingShipmentActualStartDate") {
              outputStr += `-items.shipmentSources.routeSegments.shippingShipmentActualStartDate`;
            } else if (item["field"] === "shipmentId") {
              outputStr += `-items.shipmentSources.shipmentId`;
            } else if (item["field"] === "shipmentStatusId") {
              outputStr += `-items.shipmentSources.shipmentStatusId`;
            } else if (item["field"] === "trackingCode") {
              outputStr += `-items.shipmentSources.packageRouteSegments.shipmentTrackingCode`;
            } else {
              outputStr = `-${item.field}`;
            }
          }
        }
      });
    setSortData((prev: any) => {
      if (prev) {
        return prev + `,${outputStr}`;
      } else {
        return outputStr;
      }
    });

    let prevOrderListingValue = {
      orderByFields: outputStr,
      appliedFilters,
      sortedInfo: {
        [sorter?.field]: sorter?.field,
        [`${sorter?.field}Type`]: sorter?.order,
      },
      filteredInfo: filters,
    };
    localStorage.setItem(
      "prevAppliedFilter",
      JSON.stringify(prevOrderListingValue)
    );
    if (appliedFilters) {
      nextSetOfProds(0, outputStr, appliedFilters, true, pageSize);
    } else {
      nextSetOfProds(0, outputStr, "", true, pageSize);
    }
  }

  const getDownloadData = () => {
    return getBulkOrderListingDetail?.length > 0
      ? getBulkOrderListingDetail.map((data: any) => {
          let shipmentId: any = data?.items?.[0]?.shipmentSources?.map(
            (sources: any) => sources?.shipmentId
          );
          let shipmentMethodEnumId =
            data?.items?.[0]?.shipmentSources?.[0]?.routeSegments?.find(
              (item: any) => item
            );
          let country = "";
          data?.parts?.map((item: any) => {
            if (item?.countryName) {
              country = item?.countryName;
            } else {
              country = "NA";
            }
            return country;
          });

          let state = "";
          data?.parts?.map((item: any) => {
            if (item?.stateName) {
              state = item?.stateName;
            } else {
              state = "NA";
            }
            return state;
          });

          let Status: any =
            orderStatus &&
            orderStatus.find((item: any) =>
              item?.key === data?.statusId ? item : ""
            );
          let ShipmentStatus: any = [];
          data?.items?.[0]?.shipmentSources?.forEach((sources: any) => {
            return shipmentStatus?.forEach((item: any) => {
              if (item?.key === sources?.shipmentStatusId) {
                return ShipmentStatus?.push(item?.value?.toUpperCase());
              }
            });
          });
          let shipMethod: any =
            shipMethodList &&
            shipMethodList?.find((item: any) =>
              item?.key === shipmentMethodEnumId?.shippingShipmentMethodEnumId
                ? item
                : ""
            );
          let shipmentSources = data?.items?.[0]?.shipmentSources;
          let trackingCode = shipmentSources
            ? shipmentSources?.map((item: any) =>
                item?.packageRouteSegments?.map(
                  (data: any) => data?.shipmentTrackingCode
                )
              )
            : [];
          let dataContent: string = "";
          data?.items?.forEach((item: any, index: number) => {
            item?.returnHeader?.forEach((header: any, headerIndex: number) => {
              const { returnId, externalId } = header;
              dataContent += `${returnId || ""}${
                externalId ? ` | ${externalId}` : ""
              }`;

              if (header?.shipmentSources) {
                header.shipmentSources?.forEach((source: any) => {
                  if (source?.packageRouteSegments) {
                    source.packageRouteSegments?.forEach(
                      (segment: any, segmentIndex: number) => {
                        dataContent += `${
                          segment.trackingCode
                            ? ` | ${segment.trackingCode}`
                            : ""
                        }`;
                      }
                    );
                  }
                });
              }
              if (
                !(
                  index === data?.items.length - 1 &&
                  headerIndex === item.returnHeader.length - 1
                )
              ) {
                dataContent += ", \n";
              }
            });
          });
          let shipmentActualStartDate = "";
          if (
            data?.items &&
            data?.items?.length > 0 &&
            data?.items?.[0]?.shipmentSources &&
            data?.items?.[0]?.shipmentSources?.length > 0 &&
            data?.items?.[0]?.shipmentSources?.[0]?.routeSegments &&
            data?.items?.[0]?.shipmentSources?.[0]?.routeSegments?.length > 0
          ) {
            data?.items?.[0]?.shipmentSources?.[0]?.routeSegments?.forEach(
              (item: any) => {
                if (item.shippingShipmentActualStartDate) {
                  shipmentActualStartDate =
                    item.shippingShipmentActualStartDate;
                }
              }
            );
          }
          let row: any = {};
          selectedColumns.forEach((column: any) => {
            switch (column) {
              case "orderId":
                row["ID"] = data?.orderId;
                break;
              case "shipmentId":
                row["Shipment ID"] = shipmentId?.join();
                break;
              case "displayId":
                row["External ID"] = data?.displayId;
                break;
              case "productStoreId":
                row["Store"] = data?.storeName;
                break;
              case "salesChannelEnumId":
                row["Sales Channel"] = enumType?.find(
                  (item: any) => item?.key === data?.salesChannelEnumId
                )?.value;
                break;
              case "shippingShipmentActualStartDate":
                row["Ship Date"] = shipmentActualStartDate
                  ? moment(shipmentActualStartDate)?.format("ddd MMM DD YYYY")
                  : "";
                break;
              case "placedDate":
                row["Date Placed"] = data?.placedDate
                  ? new Date(data?.placedDate).toDateString()
                  : "";
                break;
              case "parts.customerName":
                row["Customer"] =
                  data?.parts?.length > 0 && data?.parts[0]?.customerName;
                break;
              case "parts.country":
                row["Country"] = data?.parts?.length > 0 && country;
                break;
              case "parts.state":
                row["State"] = data?.parts?.length > 0 && state;
                break;
              case "statusId":
                row["Order Status"] = Status?.value?.toUpperCase();
                break;
              case "shipmentStatusId":
                row["Shipment Status"] = ShipmentStatus?.join();
                break;
              case "grandTotal":
                row["Total"] = `$${Number(data?.grandTotal || 0).toFixed(2)}`;
                break;
              case "shippingShipmentMethodEnumId":
                row["Ship Method"] = shipMethod?.value;
                break;
              case "trackingCode":
                row["Tracking Code"] = trackingCode?.join();
                break;
              case "returnDetails":
                row["Return Info (ReturnID | RMA | Tracking)"] = dataContent;
                break;
              case "segmentation":
                row["Segmentation"] = data?.segmentation;
                break;
            }
          });

          return row;
        })
      : [];
  };
  const customerSearchNextSet = async (pageIndex: any) => {
    setIsCustomerSearchLoading(true);
    await dispatch(
      getCustomersList({
        setPageCount,
        setMaxPageIndex: setCustMaxPageIndex,
        pageIndex,
        searchTerm,
      })
    );
    setIsCustomerSearchLoading(false);
  };
  const loadProdsOnBtnClick = (type: string) => {
    if (type === "left") {
      if (
        custMaxPageIndex * 1 === 1 ||
        !currCustListIndex ||
        currCustListIndex * 1 === 0 ||
        (Math.abs(currCustListIndex * 1 - custMaxPageIndex * 1) > 1 &&
          currCustListIndex * 1 > custMaxPageIndex * 1) ||
        currCustListIndex * 1 === 1
      ) {
        return;
      }
      const passedIndex = currCustListIndex * 1 - 2;
      setCurrCustListIndex((p: any) => p * 1 - 1);
      customerSearchNextSet(passedIndex);
    } else {
      if (!currCustListIndex || currCustListIndex * 1 >= custMaxPageIndex) {
        return;
      }
      const passedIndex = currCustListIndex;
      setCurrCustListIndex((p: any) => p * 1 + 1);
      customerSearchNextSet(passedIndex);
    }
  };

  return (
    <div className="h-screen">
      <Loader
        isLoading={isLoading}
        styles={{
          overlay: (base: any) => ({
            ...base,
            backgroundColor: "",
          }),
        }}
      >
        <Modal
          title="Advance Find"
          visible={advanceFindOpen}
          footer={null}
          onCancel={() => {
            setAdvanceFindOpen(false);
            setProductList([]);
            setSelectedProduct(null);
          }}
        >
          <Loader
            isOverlayLoading={advanceFindLoading}
            styles={{
              overlay: (base: any) => ({
                ...base,
                backgroundColor: "",
              }),
            }}
          >
            <Form
              form={AdvanceFindForm}
              onFinish={async (val: any) => {
                if (Object.values(val).every((value) => !value)) {
                  alert("Please select at least one value!");
                  return;
                }
                const advanceFindFormData: any = {
                  customerId: val?.customerName,
                  countryId: val?.country,
                  stateId: val?.state,
                  productId: selectedProduct,
                };
                setIsAdvanceFindLoading(true);
                await dispatch(
                  getOrderListing({
                    setgetOrderListingDetail,
                    setGetBulkOrderListingDetail,
                    setMaxPageIndex,
                    orderByFields: sortData,
                    setRecordCount,
                    pageIndex: 0,
                    pageSize,
                    // searchString: orderStatus,
                    placedDate_from: selectedFromDate,
                    placedDate_thru: selectedThruDate,
                    statusId,
                    queryString,
                    facilityId: facilityId ? facilityId : "",
                    onlyShipped,
                    appliedFilters: activeAppliedFilters,
                    advanceFindFormData,
                    advanceFindOpen,
                    isReturnedOrders: isReturnedOrders ? true : false,
                    setSelectedProduct,
                  })
                );
                setAdvanceFindAppliedFilter(advanceFindFormData);
                setIsAdvanceFindLoading(false);
                setAdvanceFindOpen(false);
                setProductList([]);
              }}
            >
              <Form.Item name="productId" className="flex-1 w-full">
                <div>
                  <h1 className="font-bold text-xs mb-0.5 sm:mb-1.5">
                    Product
                  </h1>
                  <Select
                    showSearch
                    value={selectedProduct}
                    placeholder="Search Product"
                    style={{ width: "100%" }}
                    defaultActiveFirstOption={false}
                    suffixIcon={null}
                    className={`border border-gray-400 py-1 w-full bg-white rounded-md focus:outline-none hover:border-primary-light focus:border-primary-light`}
                    filterOption={false}
                    onSearch={async (value) => {
                      setSearchableString(() => value);
                      setProductList([]);
                      debounceFn(
                        await dispatch(
                          searchProductListing({
                            productList,
                            setProductList,
                            setMaxPageIndex,
                            queryString: value,
                            currPage: 0,
                          })
                        )
                      );
                    }}
                    onSelect={(value: any) => {
                      setSelectedProduct(value);
                    }}
                    notFoundContent={null}
                    options={productList?.map((item: any) => {
                      const { identifications, productId, name } = item;
                      const sku = identifications?.find(
                        (iden: any) => iden?.productIdTypeEnumId === "PidtSku"
                      );
                      const upc = identifications?.find(
                        (iden: any) => iden?.productIdTypeEnumId === "PidtUpca"
                      );
                      const idValue =
                        sku?.idValue || upc?.idValue || productId || "";
                      return {
                        label: `${idValue}${name ? ` | ${name}` : ""}`,
                        value: item?.productId,
                      };
                    })}
                    onPopupScroll={async (e: any) => {
                      const { target } = e;
                      if (
                        (target as any).scrollTop +
                          (target as any).offsetHeight ===
                        (target as any).scrollHeight
                      ) {
                        if (!currIndex || currIndex * 1 === 0) {
                          return;
                        }
                        if (currIndex * 1 > maxPageIndex) {
                          return;
                        }
                        setCurrIndex((prev: any) => prev * 1 + 1);
                        localStorage.setItem("productListCurrIndex", currIndex);
                        await dispatch(
                          searchProductListing({
                            productList,
                            setProductList,
                            queryString: searchableString,
                            currPage: currIndex,
                          })
                        );
                      }
                    }}
                  />
                </div>
              </Form.Item>
              <Form.Item name="customerName">
                <CustomDropdown
                  styles={{
                    overlay: (base: any) => ({
                      ...base,
                      backgroundColor: "",
                    }),
                  }}
                  isSearchBoxVisible={true}
                  title="Customer Name"
                  placeholder="Customer Name"
                  data={customersList}
                  maxPageIndex={custMaxPageIndex}
                  currIndex={currCustListIndex}
                  isPaginated={true}
                  loadProds={loadProdsOnBtnClick}
                  loading={customerSearchLoading}
                  searchBoxOnChange={(input: any) => {
                    setSearchTerm(input);
                    dispatch(
                      getCustomersList({
                        setPageCount: setRecordCount,
                        setMaxPageIndex: setCustMaxPageIndex,
                        pageIndex: 0,
                        searchTerm: input,
                      })
                    );
                  }}
                />
              </Form.Item>
              <Form.Item name="country">
                <CustomDropdown
                  title="Country"
                  placeholder="Country"
                  isDisabled={activeAppliedFilters.includes("countryId=")}
                  data={countriesList}
                  onChange={(val: any) => {
                    AdvanceFindForm.setFieldValue("state", "");
                    dispatch(getGeoStates(val));
                  }}
                  searchable
                />
              </Form.Item>
              <Form.Item name="state">
                <CustomDropdown
                  title="State"
                  placeholder="State"
                  data={statesList}
                  searchable
                />
              </Form.Item>
              <Button buttonText="Find" />
            </Form>
          </Loader>
        </Modal>
        <div className="flex">
          <div className="search-prod w-full ltmob:w-4/6 mdlarge:w-3/5 mdxlarge:w-2/5 mb-8">
            <span className="search-icon">
              <SearchOutlined style={{ fontSize: 17 }} />
            </span>
            <input
              placeholder="Search Orders"
              defaultValue={searchedQuery}
              value={searchedQuery}
              className="border border-gray-400 px-2 py-2 w-full rounded-md focus:outline-none hover:border-primary-light focus:border-primary-light"
              onKeyPress={async (e: any) => {
                if (e.key === "Enter") {
                  setIsTableLoading(true);
                  setIsSearch("Searched");
                  await dispatch(
                    getOrderListing({
                      setgetOrderListingDetail,
                      setGetBulkOrderListingDetail,
                      setMaxPageIndex,
                      setRecordCount,
                      pageIndex: 0,
                      pageSize,
                      queryString: e.target.value,
                      placedDate_from: selectedFromDate
                        ? selectedFromDate
                        : fromDate
                        ? fromDate
                        : "",
                      placedDate_thru: selectedThruDate
                        ? selectedThruDate
                        : thruDate
                        ? thruDate
                        : "",
                      statusId,
                      facilityId: facilityId ? facilityId : "",
                      onlyShipped,
                      appliedFilters: activeAppliedFilters,
                      isReturnedOrders: isReturnedOrders ? true : false,
                    })
                  );
                  setSearchedQuery("");
                  setSearchableString(e.target.value);
                  setIsTableLoading(false);
                }
              }}
              onChange={async (e: any) => {
                setSearchedQuery(e.target.value);
              }}
            />
          </div>
          <div>
            <Button
              buttonText="Advance Find"
              paddingX="px-2"
              classNames="mx-3 bg-blue-900"
              onClick={() => setAdvanceFindOpen(true)}
            />
          </div>
        </div>
        <div className="bg-white p-5 pb-0.5 rounded-lg shadow-sm">
          <div className="flex flex-row justify-end gap-5 mb-3">
            <div className="ltmob:flex ltmob:flex-row ltmob:items-center">
              <div className="flex flex-row items-center">
                <CSVLink
                  filename={"OrderDetails.csv"}
                  data={getDownloadData()}
                  className="cursor-pointer"
                >
                  <div className="flex flex-row items-center">
                    <RiDownloadLine
                      style={{ fontSize: "16px" }}
                      className="text-primary"
                    />
                    <p className="ml-1 text-xs font-bold text-primary">CSV</p>
                  </div>
                </CSVLink>
              </div>
            </div>
            <div>
              <Dropdown
                overlay={
                  <Menu
                    onClick={async (val: any) => {
                      if (val.key === "ClearFilter") {
                        setResetInputField(true);
                        setSearchableString("");
                        setIsTableLoading(true);
                        await dispatch(
                          getOrderListing({
                            setgetOrderListingDetail,
                            setGetBulkOrderListingDetail,
                            setMaxPageIndex,
                            orderByFields: sortData,
                            setRecordCount,
                            pageIndex: 0,
                            pageSize,
                            // searchString: orderStatus,
                            placedDate_from: fromDate ? fromDate : "",
                            placedDate_thru: thruDate ? thruDate : "",
                            statusId,
                            queryString,
                            facilityId: facilityId ? facilityId : "",
                            onlyShipped,
                            appliedFilters: "",
                            isReturnedOrders: isReturnedOrders ? true : false,
                          })
                        );
                        localStorage.removeItem("orderListingFromDate");
                        localStorage.removeItem("orderListingThruDate");
                        localStorage.removeItem("prevAppliedFilter");
                        setActiveAppliedFilters("");
                        setSelectedFromDate("");
                        setSelectedThruDate("");
                        setDatePickerFrom("");
                        setDatePickerThru("");
                        setFilteredInfo({});
                        AdvanceFindForm?.resetFields();
                        setAdvanceFindAppliedFilter({});
                        setIsTableLoading(false);
                      } else {
                        setIsTableLoading(true);
                        await dispatch(
                          getOrderListing({
                            setgetOrderListingDetail,
                            setGetBulkOrderListingDetail,
                            setMaxPageIndex,
                            orderByFields: "",
                            setRecordCount,
                            pageIndex: currIndex * 1 - 1,
                            pageSize,
                            // searchString: orderStatus,
                            placedDate_from: selectedFromDate
                              ? selectedFromDate
                              : fromDate
                              ? fromDate
                              : "",
                            placedDate_thru: selectedThruDate
                              ? selectedThruDate
                              : thruDate
                              ? thruDate
                              : "",
                            statusId,
                            queryString,
                            facilityId: facilityId ? facilityId : "",
                            onlyShipped,
                            appliedFilters: activeAppliedFilters,
                            isReturnedOrders: isReturnedOrders ? true : false,
                          })
                        );
                        localStorage.removeItem("prevAppliedFilter");
                        setSortedInfo({});
                        setSortData("");
                        setIsTableLoading(false);
                      }
                    }}
                  >
                    <Menu.Item key="ClearFilter">Clear Filters</Menu.Item>
                    <Menu.Item key="ClearSorter">Clear Sorter</Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
                placement="topLeft"
              >
                <EllipsisOutlined style={{ fontSize: 18 }} />
              </Dropdown>
            </div>
          </div>

          <Table
            rowKey="shipMethod"
            dataSource={getOrderListingDetail}
            columns={columns}
            isServerRendered={recordCount ? true : false}
            isPaginated={true}
            loadNextProds={loadNextProds}
            recordCount={recordCount}
            onChange={onChange}
            loading={isTableLoading}
            resetInputField={resetInputField}
            showColumnFilter={true}
            onSelectedColumnsChange={setSelectedColumns}
            // rowClassName={(record: any) => {
            //   if (record?.items?.[0]?.returnHeader) {
            //     return "bg-orange-100";
            //   } else {
            //     return "";
            //   }
            // }}
          />
        </div>
      </Loader>
    </div>
  );
};

export default OrderListing;
